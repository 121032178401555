import izitoast from 'izitoast'
import moment from 'moment'

// function isAbv(value) {
//     return value && value.buffer instanceof ArrayBuffer && value.byteLength !== undefined;
// }

var errorService = async function (e) {
    window.error = e;
    if(e.response.status == 503){
        izitoast.error({ message: `${e.response.status} - ${e.response.statusText}` });
    } else if (e && e.response) {
        if (e && e.response && e.response.data && e.response.data.error_description) {
            izitoast.warning({
                message: `${e.response.data.error_description}`
            })
        }
        if (e && e.response && e.response.data && e.response.data.error_msg) {
            izitoast.warning({
                message: `${e.response.data.error_msg}`
            })
        }
        if (e && e.response && e.response.data && e.response.data.errorMessage) {
            izitoast.warning({
                message: `${e.response.data.errorMessage}`
            })
        }
        if (e && e.response && e.response.data && e.response.data.detail) {
            izitoast.warning({
                title: `${e.response.data.title} (${e.response.data.status})`,
                message: `${e.response.data.detail}`
            })
        } else if (e.response && e.response.status === 503) {
            izitoast.error({
                message: `${e.response.status} ${e.response.data.requestId} ${e.response.data.error} ${e.response.data.message} ${e.response.data.path} ${moment(e.response.data.timestamp).format('DD/MM/YYYY hh:mm:ss')}`
            })
        }
        else if (e.response.data.errors) {
            const data = e.response.data;
            const errors = data.errors;
            if (Array.isArray(errors)) {
                for(const error of errors) {
                    var message = `${data.code} at field ${error.field} - ${error.message}`
                    if(message && message.indexOf('Invalid phone') != -1){
                        message = "Nomor Telepon Invalid"
                    }
                    if(message && message.indexOf('name resolution') != -1){
                        message = "Nama Invalid"
                    }
                    if(message && message.indexOf('liveness') != -1){
                        message = "Foto Selfie Invalid"
                    }
                    izitoast.error({
                        message: message
                    })
                }
                return;
            }
            
            izitoast.error({ message: `${errors}` });
        }
        else {
            let arrayMessage = String.fromCharCode.apply(null, new Uint8Array(e.response.data));
            
            if(arrayMessage){
                var objectMessage = JSON.parse(arrayMessage)
                izitoast.warning({
                    title: objectMessage.title,
                    message: `${objectMessage.detail}`
                })
            }else{
                izitoast.error({
                    message: `${e.message}`
                }
            )

            }
        }
    }
}

export default errorService;

