import React, {
    createContext,
    createElement,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { cloneDeep } from "lodash";
import { isString } from "~utils/guards";
import {
    ROLE_ADMIN_COMPANY_DECD,
    ROLE_ADMIN_DECD,
    ROLE_SUPERVISOR_DECD,
    ROLE_USER,
    ROLE_USER_DECD,
} from "~utils/constants";
import userService from "~modules/_modules/User/User.service";
import { useHistory } from "react-router-dom";
import errorService from "~services/errorService";
import accessService from "~modules/_modules/ConfigAccess/access.service";

/** @type {UserContext} */
export const UserContext = createContext({});

UserContext.createProviderValue = (user, refreshFn, refreshAccessFn) => {
    const isValidUser = isString(user?.id);

    if (!isValidUser) {
        const noop = () => false;
        return {
            done: false,
            isAdmin: noop,
            isAdminCompany: noop,
            isAdmins: noop,
            isSupervisor: noop,
            isUser: noop,
            hasAnyRole: noop,
            hasAnyPermission: noop,
            hasAnyAccess: noop,
            refresh: () => Promise.resolve(),
            refreshAccess: () => Promise.resolve(),
            onAuthorized: () => {},
        };
    } else {
        const definedRoles = atob(user.roles)
            .split(";")
            .map((role) => role.toLowerCase());

        /** @type {UserContextValue} */
        const context = {
            ...user,
            done: true,
            hasAnyRole(...roles) {
                return roles
                    .map((role) => definedRoles.includes(role.toLowerCase()))
                    .includes(true);
            },
            hasAnyPermission(...permissions) {
                return permissions
                    .map((prm) => context.permissions.includes(prm))
                    .includes(true);
            },
            hasAnyAccess(...access) {
                return access.map((acs) => context.menu.includes(acs)).includes(true);
            },
            isAdmin() {
                return context.hasAnyRole(ROLE_ADMIN_DECD);
            },
            isAdminCompany() {
                return context.hasAnyRole(ROLE_ADMIN_COMPANY_DECD);
            },
            isAdmins() {
                return (
                    definedRoles.filter((e) => e.toLowerCase().startsWith("admin"))
                        .length > 0
                );
            },
            isSupervisor() {
                return context.hasAnyRole(ROLE_SUPERVISOR_DECD);
            },
            isSupervisors() {
                return (
                    definedRoles.filter(
                        (e) =>
                            e.toLowerCase().startsWith("supervisor") ||
                            e.toLowerCase().startsWith("spv")
                    ).length > 0
                );
            },
            isUser() {
                return context.hasAnyRole(ROLE_USER_DECD);
            },

            refresh: refreshFn,
            async refreshAccess() {
                // console.log("refresh access menu");
                await refreshAccessFn(user.id);
            },
        };

        return Object.freeze(context);
    }
};

/**
 * Hook biar ga usah ngulang fetching current user
 * @returns
 */
export function useEmetUser() {
    return useContext(UserContext);
}

/**
 *
 * @param {AuthorizedCallback} cb
 * @param {React.DependencyList} deps
 */
export function useAuthorized(cb = () => {}, deps = []) {
    const user = useEmetUser();
    useEffect(() => {
        if (!user.done) return;
        const result = cb(user);
        if (result instanceof Promise) return;
        else return result;
    }, [user, ...deps]);
}

export default function UserProvider({ children }) {
    /** @type {ReactState<EmetUser>} */
    const [user, setUser] = useState();

    const timeout = useRef();

    const getUser = async (throwErr = false) => {
        try {
            const { data } = await userService.userInfo();
            setUser(data);
            var encryptThis = {
                name: data.name,
                email: data.email
            }
            localStorage.setItem('dcia', btoa(JSON.stringify(encryptThis)))
            return true;
        } catch (e) {
            if (throwErr) errorService(e);
            return false;
        }
    };

    /**
     * @param {string} uid
     */
    const getAccess = async (uid) => {
        const accessRes = await accessService.getUserAccess(uid);
        setUser((prev) => ({ ...prev, menu: accessRes.data }));
    };

    useEffect(() => {
        console.log("Keycloak", window.eath);
        getUser().then(res => {
            if (res) return;

            let retry = 3;
            const recurse = () => {
                getUser(retry === 0).then(res => {
                    if (!res) {
                        if (retry < 1) return;
                        retry -= 1;
                        setTimeout(recurse, 2000);
                    }
                })
            }

            recurse();
        });
    }, []);

    // useEffect(() => {
    //     if (!user) {
    //         timeout.current = setTimeout(getUser, 3000);
    //         return () => clearTimeout(timeout.current);
    //     }
    // }, [!!user]);

    return createElement(UserContext.Provider, {
        value: UserContext.createProviderValue(user, getUser, getAccess),
        children,
    });
}
